import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class BannerWithImage extends Component {
  static propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    image: PropTypes.string.isRequired,
  };

  static defaultProps = {
    backgroundColor: '#b7b56a',
    fontColor: '#ffffff',
  };

  render() {
    const { header, subheader, content, image } = this.props;

    const bannerStyles = {
      backgroundColor: this.props.backgroundColor,
      color: this.props.fontColor,
    };

    return (
      <div className="component banner-with-image" style={bannerStyles}>
        <div className="banner-inner-wrapper">
          <div className="banner-inner-wrapper-2">
            <div className="image-content">
              <img src={image} alt="" />
            </div>
            <div className="banner-content">
              <h1 className="banner-content-header">{header}</h1>
              <div className="banner-content-subheader">{subheader}</div>
              <div className="banner-content-content">{content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerWithImage;
