import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import BannerWithImage from '../../../core/BannerWithImage';

import './index.css';
import Team from './components/Team';
import TeamMember from './components/TeamMember';

import teamImage from './images/TR-Group1-web.jpg';
import richardImage from './images/Think-Res-Richard-01-web.jpg';
import alfredImage from './images/Think-Res-alfred-42-web.jpg';
import atishImage from './images/Think-Res-Atish-20-web.jpg';
import blaqueImage from './images/TR Blaque Web.jpg';
import sharonImage from './images/Think-Res-sharon-33-web.jpg';
import seanImage from './images/TR-Sean-Web-sq.jpg';
import kevinImage from './images/Think-Res-Kevin-27-web.jpg';
import ilsaImage from './images/Think-Res-Ilsa-10-web.jpg';
import rayImage from './images/Think-Res-Ray-05-web.jpg';
import lauraSImage from './images/TR-Laura-web.jpg';
import shenaImage from './images/Think-Res-Shena-32-web.jpg';
import adamImage from './images/TR-Adam-web.jpg';
import andyImage from './images/TR-Andy-web.jpg';
import zachImage from './images/TR-Zach-web.jpg';
import andrewWallImage from './images/TR-Andrew-web.jpg';
import julianImage from './images/TR-Julian-web.jpg';
import sarahImage from './images/sarah_web.png';
import brettImage from './images/Brett-web.png';
import katImage from './images/kat-web.png';
import jadeImage from './images/jade-web.png';
import allisonImage from './images/allison-web.png';
import traceyImage from './images/tracey-web.png';

import placeholderImage1 from './images/silhouette01.svg';
import placeholderImage2 from './images/silhouette03.svg';

export class OurTeamPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component our-team-page">
          <Helmet title="Our team – ThinkReservations">
            <meta
              name="description"
              content="ThinkReservations has a team of experienced hospitality and technical professionals. We’re proud to serve your business!"
            />
          </Helmet>

          <BannerWithImage
            header="Our Team"
            subheader="Exceeding your expectations"
            content="The ThinkReservations team is made up of employees who have extensive hospitality and technical experience. We are proud to be working together to better serve your business."
            image={teamImage}
            backgroundColor="#3e3935"
          />

          <Team name="Leadership" backgroundColor="#f5f3f3">
            <TeamMember
              image={richardImage}
              imageWidth={186}
              imageHeight={279}
              name="Richard Aday"
              title="Founder, CEO &amp; Principal Engineer"
              description="Richard is an Electrical and Computer Engineer with experience from companies such as Amazon.com, Microsoft, and IBM. After working with thousands of lodging establishments, his experience allows him to provide a fresh perspective on processes currently being used in the industry and how to take them to the next level."
              type="large"
            />
            <TeamMember
              image={alfredImage}
              imageWidth={186}
              imageHeight={279}
              name="Alfred Aday"
              title="Founder, CTO &amp; Principal Engineer"
              description="Alfred brings extensive technical experience as an Electrical and Computer Engineer who has worked at companies like Amazon.com, IBM, and Lockheed Martin. His ability to design and implement complex systems across a team allows ThinkReservations the ability to respond quickly to industry demands."
              type="large"
            />

            <TeamMember
              image={seanImage}
              imageWidth={186}
              imageHeight={279}
              name="Sean Rolsen"
              title="Director of Customer Success"
              description='Sean Rolsen grew up in the lodging industry. Over a span of almost 20 years Sean has performed every job conceivable at the Inn and restaurant from property management to concierge services. At ThinkReservations, Sean leads the Operations Team and uses his passion and knowledge to help "Thinkers" grow their business.'
              type="large"
            />

            <TeamMember
              image={shenaImage}
              imageWidth={186}
              imageHeight={279}
              name="Shena Hesselbein"
              title="Director of Operations"
              description="After getting her degree in hospitality and tourism management at Florida International University, then working as an innkeeper for several years at her family's Pennsylvania bed and breakfast, Shena brings both passion and experience to her role as an Office Manager."
              type="large"
            />

            <TeamMember
              image={blaqueImage}
              imageWidth={186}
              imageHeight={279}
              name="Blaque Mackintosh"
              title="Director of Product & Design"
              description="Blaque got his start in software experience design in 1994, architecting some of the earliest networked applications on the Internet. Blaque has nearly 30 years of experience in the field, holding design leadership positions at Sapient, Amazon.com, IBM, General Electric. He specializes in collaborative productivity software, thinking the big thoughts and polishing the fine details of user experience. His fields of expertise include interaction design, ethnographic research, usability analysis, and visual and graphic design."
              type="large"
            />
          </Team>

          <Team name="Customer Success Team">
            <TeamMember
              image={kevinImage}
              imageWidth={144}
              imageHeight={144}
              name="Kevin Heinrich"
              title="Technical Support Engineer"
              description="Kevin holds a certification in graphic and web design from Skidmore College and is a certified AWS Cloud Solutions Architect. Kevin provides account management and design services to ThinkReservations' client base. He is passionate about delivering effective top tier level support to both clients and ThinkReservations staff. For the past 20 years, Kevin has specialized in technical consultation, IT systems management, and design services for hospitality businesses in metro New York and New England."
            />
            <TeamMember
              image={allisonImage}
              imageWidth={144}
              imageHeight={144}
              name="Allison Bruen"
              title="Customer Success Manager"
              description="Allison joined the ThinkReservations team with over 15 years of customer service and hospitality experience.  Having launched and managed a boutique hotel in Pennsylvania, Allison is passionate about using her insight into what hoteliers need from a property management system to ensure the success of all ThinkReservations customers."
            />
            <TeamMember
              image={rayImage}
              imageWidth={144}
              imageHeight={144}
              name="Ray Rosset"
              title="Account Manager"
              description="Ray works directly with our customers to help them understand how they can reach their goals using ThinkReservations. As former owner/innkeeper of Lookout Point Lakeside Inn for 14 years, Ray adds a wealth of real-world experience to our team. He is a Chartered Accountant (Canada) with many years of corporate accounting and finance roles in major retail organizations."
            />
            <TeamMember
              image={adamImage}
              imageWidth={144}
              imageHeight={144}
              name="Adam Strickland"
              title="Account Manager"
              description="Adam's past experiences include managing a lodging property and providing support for both RezOvation and Webervations. Adam has a lot of experience in the lodging industry and uses his valuable knowledge to help properties solve problems and learn how to improve their businesses."
            />
            <TeamMember
              image={andyImage}
              imageWidth={144}
              imageHeight={144}
              name="Andrew Martin"
              title="Account Manager"
              description="Andy has worked in lodging software since 2005 supporting both RezOvation and Webervations prior to joining ThinkReservations. He utilizes a wealth of knowledge about lodging software and industry trends to assist customers in using ThinkReservations to manage their business and to resolve any issues that may arise."
            />
            <TeamMember
              image={zachImage}
              imageWidth={144}
              imageHeight={144}
              name="Zach Martin"
              title="Account Manager"
              description="Zach has been working in the property management system space since 2007.  After supporting both RezOvation and Webervations he is now working at ThinkReservations. He uses his experience to help innkeepers find the simplest solutions to manage their business."
            />
            <TeamMember
              image={jadeImage}
              imageWidth={144}
              imageHeight={144}
              name="Jade Moser"
              title="Onboarding Specialist"
              description="Jade received her degree in Hospitality Management from Pennsylvania State University. She has an extensive career in hospitality at Carnival Cruise Lines, Hotels & Resorts, Best Western Premier Resorts, and Hilton Hotels. Her diverse background in hospitality, sales, & travel offered the opportunity to assist in opening the world’s first Cartoon Network Hotel, located in Lancaster, PA. There, she worked within the sales department as the Sales Manager to drive sales, launch the hotel's property management system, and network. Jade loves helping others and providing exceptional experiences."
            />
            <TeamMember
              image={brettImage}
              imageWidth={144}
              imageHeight={144}
              name="Brett Knasiak"
              title="Onboarding Specialist"
              description="Brett has been in the hospitality industry for two decades, working in both corporate and 
              private restaurants and hotels. Starting in 2016 he spent 6 years at The Washington House Hotel & 
              Restaurant, a ThinkReservations client in Sellersville, Pennsylvania where he held the title of Hotel 
              Manager and Beverage Director. As an Onboarding Specialist he applies his insight of the needs of 
              lodging properties to make a smooth transition into being an active ThinkReservations user."
            />
            <TeamMember
              image={katImage}
              imageWidth={144}
              imageHeight={144}
              name="Kathleen Ng"
              title="Onboarding Specialist"
              description="Kathleen started her hospitality career with the Hilton Garden Inn in Hershey, PA. Then, 
              she managed the Front Office team at the newly opened IHG property - Hotel Indigo Harrisburg Hershey. 
              Kathleen had the opportunity to assist in opening and operating the world's first Cartoon Network Hotel. 
              There she worked as the Activities and Aquatics manager and later transitioned into the Front Office manager 
              position. Kathleen is excited to join the team and help lead Think Reservations customers to success."
            />
            <TeamMember
              image={sarahImage}
              imageWidth={144}
              imageHeight={144}
              name="Sarah Roderick"
              title="Onboarding Specialist"
              description="Sarah graduated from Christopher Newport University in Virginia with a BA in Spanish and minors in 
              Leadership Studies and Business Administration. Her lodging experience began while helping to launch a small 
              bed and breakfast in eastern North Carolina, followed by a client services role at a locally owned hotel in the 
              Osa Peninsula of Costa Rica. Most recently, she managed the Guest Services team at a luxury inn and restaurant 
              in northern Virginia. Sarah is excited to utilize her past experiences and passion for hospitality to assist 
              ThinkReservations clients!"
            />
          </Team>

          <Team name="Sales and Marketing" backgroundColor="#f5f3f3">
            <TeamMember
              image={lauraSImage}
              imageWidth={144}
              imageHeight={144}
              name="Laura Scheidegger"
              title="Account Executive"
              description="Laura brings 20+ years of hospitality knowledge to the ThinkReservations team as a Sales Account Manager. Her diverse background in hospitality and travel allows her the opportunity to guide our potential properties through the sales process with ease, leaving them confident they have made the best choice."
            />
            <TeamMember
              image={traceyImage}
              imageWidth={144}
              imageHeight={144}
              name="Tracey Heber"
              title="Sales Development Representative"
              description="Tracey has a degree in Hospitality and Tourism Management from Grand Valley State University and has experience in the lodging industry from chain hotels to boutique and private inns. She has also been involved in the tourism sector and worked as a Family Readiness Support Assistant for the US Army. Tracey and her family volunteer as a foster family for a local dog rescue and enjoy an enterprising game of monopoly. Fun fact: Tracey participated in and completed the 2020 Honolulu Marathon."
            />
          </Team>

          <Team name="Technical Team">
            <TeamMember
              image={atishImage}
              imageWidth={144}
              imageHeight={144}
              name="Atish Kalyan"
              title="Principal Engineer"
              description="Atish received a degree in computer science from The Georgia Institute of Technology. A job offer from Amazon.com brought Atish to Seattle where he spent 9 years developing Amazon's fulfillment center software, integrating Amazon and Zappos systems, overseeing the deployment of the Kiva Robotics system, and innovating on the Amazon Prime Air team. Post-Amazon, Atish has worked at start-ups and is excited to be working at ThinkReservations."
            />
            <TeamMember
              image={ilsaImage}
              imageWidth={144}
              imageHeight={144}
              name="Ilsa Gruber"
              title="Project Manager"
              description="While getting her degree in Hotel Administration from Cornell University, Ilsa joined the ThinkReservations team as an Account Manager. After moving to the role of Project Manager in 2019, Ilsa now acts as a liaison between the technical and operations teams; facilitates the process of new features, integrations, and software improvements; and serves as the ScrumMaster within our product development team. Ilsa loves using her real world hotel experience to help hoteliers succeed."
            />
            <TeamMember
              image={andrewWallImage}
              imageWidth={144}
              imageHeight={144}
              name="Andrew Wall"
              title="Product Manager"
              description="Andrew has a Master's degree in business and hospitality from Cornell University and a diverse range of experiences working in both hotel operations and hotel technical administration. He loves leveraging his expertise to help create efficient and user friendly workflows and system functionalities, enabling users to spend less time on the computer and more time personalizing and enhancing the experiences of their guests."
            />
            <TeamMember
              image={julianImage}
              imageWidth={144}
              imageHeight={144}
              name="Julian Naydichev"
              title="Sr. Software Engineer"
              description="Julian is a code wizard with several years of experience working at Amazon. He’s a master puzzler, tinkerer and typist extraordinaire (even having beaten Alfred in a competition!). He’s lived in Los Angeles, Seattle, and now calls the beautiful canal-filled city of Amsterdam home. Hij spreekt een beetje Nederlands, maar hij weet ook Java, Python en Bash."
            />
            <TeamMember
              image={placeholderImage1}
              imageWidth={144}
              imageHeight={144}
              name="Levi Robertson"
              title="Front End Developer"
              description="Levi is a self-taught developer who likes to joke that he was raised by the internet. As early as he can remember he was either modding games, customizing his and other's myspace pages, or trying to take electronics apart. He has a real passion for understanding how things work and improving on them. His passion for the front-end has helped various teams, from large companies like American Express to startups, build a beautiful and effective product."
            />
            <TeamMember
              image={placeholderImage1}
              imageWidth={144}
              imageHeight={144}
              name="Nate Balas"
              title=""
              description="Nate received his BS Computer Science degree from the University of Minnesota before moving to Seattle to work with Amazon. He worked on the Elastic Beanstalk team for 2.5 years until moving to Amsterdam to assist with the Cloud9 acquisition for another 3 years. Now residing in Utrecht he looks forward to working with ThinkReservations."
            />
          </Team>
        </div>
      </App>
    );
  }
}

export default OurTeamPage;
