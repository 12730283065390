import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

import Image from '../../../../../core/Image';

export class TeamMember extends Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.oneOf(['normal', 'large']),
  };

  render() {
    const {
      image,
      imageWidth,
      imageHeight,
      name,
      title,
      description,
      type,
    } = this.props;

    return (
      <div className={`component team-member team-member-type-${type}`}>
        <Image
          src={image}
          width={imageWidth}
          height={imageHeight}
          border={true}
        />
        <div className="team-member-name">{name}</div>
        <div className="team-member-title">{title}</div>
        <div className="team-member-description">{description}</div>
      </div>
    );
  }
}

export default TeamMember;
