import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

import Panel from '../../../../../core/Panel';

export class Team extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
  };

  render() {
    return (
      <div className="component team">
        <Panel backgroundColor={this.props.backgroundColor}>
          <div class="team-name">{this.props.name}</div>
          <div class="team-members">{this.props.children}</div>
        </Panel>
      </div>
    );
  }
}

export default Team;
